import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import LongdoMap from 'longdo-map-vue'
import "@/core/plugins/prismjs";
const app = createApp(App);

app.use(store);
app.use(LongdoMap, {
    load: {
      apiKey: '252d0f2d36bbea05c99a074d0f66158e',
    }
  })
app.use(router);
app.use(ElementPlus);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Datepicker", Datepicker);
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
