
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { themeMode } from "@/core/helpers/config";
import { initializeComponents } from "@/core/plugins/keenthemes";
import axios from "axios";
export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();

    onMounted(async () => {
       const getGuestToken = await axios.get(
        process.env.VUE_APP_API_URL + "/getGuestToken"
      );
      localStorage.setItem("tokenGuest", getGuestToken.data.data);
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      /**
       *  Sets a mode from configuration
       */
      store.dispatch(Actions.SET_THEME_MODE_ACTION, "light");

      nextTick(() => {
        initializeComponents();

        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });
    });
  },
});
