import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Home.vue"),
        meta: {
          pageTitle: "หน้าหลัก",
          breadcrumbs: ["หน้าหลัก"],
        },
      },
      {
        path: "/course",
        name: "course-list",
        component: () => import("@/views/menu/course/CourseList.vue"),
        meta: {
          pageTitle: "คอร์สออนไลน์",
          breadcrumbs: ["คอร์สออนไลน์"],
        },
      },
      {
        path: "/course-detail/:id",
        name: "course-detail",
        component: () => import("@/views/menu/course/CourseDetail.vue"),
        meta: {
          pageTitle: "คอร์สออนไลน์",
          breadcrumbs: ["รายละเอียด"],
        },
      },
      {
        path: "/course-learn/:id",
        name: "course-learn",
        component: () => import("@/views/menu/course/CourseLearn.vue"),
        meta: {
          pageTitle: "คอร์สออนไลน์",
          breadcrumbs: ["บทเรียน"],
        },
      },
      {
        path: "/course-exam/:cId/:secId/:chId/:exId/:type",
        name: "course-exam",
        component: () => import("@/views/menu/course/CourseExam.vue"),
        meta: {
          pageTitle: "คอร์สออนไลน์",
          breadcrumbs: ["ข้อสอบ"],
        },
      },
      {
        path: "/cyber-village",
        name: "cyber-village-list",
        component: () => import("@/views/menu/cyber-village/CyberVillageList.vue"),
        meta: {
          pageTitle: "ชุมชนสัมพันธ์",
          breadcrumbs: ["ชุมชนสัมพันธ์"],
        },
      },
      {
        path: "/cyber-village-detail",
        name: "cyber-village-create",
        component: () => import("@/views/menu/cyber-village/CyberVillageDetail.vue"),
        meta: {
          pageTitle: "ชุมชนสัมพันธ์",
          breadcrumbs: ["เพิ่มชุมชนสัมพันธ์"],
        },
      },
      {
        path: "/cyber-village-detail/:id",
        name: "cyber-village-detail",
        component: () => import("@/views/menu/cyber-village/CyberVillageDetail.vue"),
        meta: {
          pageTitle: "ชุมชนสัมพันธ์",
          breadcrumbs: ["ชุมชนสัมพันธ์"],
        },
      },
      {
        path: "/knowledge",
        name: "knowledge-list",
        component: () => import("@/views/menu/knowledge/KnowledgeList.vue"),
        meta: {
          pageTitle: "คลังความรู้",
          breadcrumbs: ["คลังความรู้"],
        },
      },
      {
        path: "/knowledge-detail/:id",
        name: "knowledge-detail",
        component: () => import("@/views/menu/knowledge/KnowledgeDetail.vue"),
        meta: {
          pageTitle: "คลังความรู้",
          breadcrumbs: ["คลังความรู้"],
        },
      },
      {
        path: "/news",
        name: "news-list",
        component: () => import("@/views/menu/news/NewsList.vue"),
        meta: {
          pageTitle: "ประชาสัมพันธ์",
          breadcrumbs: ["ประชาสัมพันธ์"],
        },
      },
      {
        path: "/news-detail/:id",
        name: "news-detail",
        component: () => import("@/views/menu/news/NewsDetail.vue"),
        meta: {
          pageTitle: "ประชาสัมพันธ์",
          breadcrumbs: ["รายละเอียด"],
        },
      },
      {
        path: "/notifications",
        name: "notifications-list",
        component: () => import("@/views/menu/notifications/NotificationsList.vue"),
        meta: {
          pageTitle: "แจ้งเตือน",
          breadcrumbs: ["แจ้งเตือน"],
        },
      },
      {
        path: "/notifications-detail/:id",
        name: "notifications-detail",
        component: () => import("@/views/menu/notifications/NotificationsDetail.vue"),
        meta: {
          pageTitle: "แจ้งเตือน",
          breadcrumbs: ["รายละเอียด"],
        },
      },
      {
        path: "/command",
        name: "command-list",
        component: () => import("@/views/menu/command/CommandList.vue"),
        meta: {
          pageTitle: "คำสั่ง",
          breadcrumbs: ["คำสั่ง"],
        },
      },
      {
        path: "/webboard",
        name: "webboard-list",
        component: () => import("@/views/menu/webboard/WebboardList.vue"),
        meta: {
          pageTitle: "เว็บบอร์ด",
          breadcrumbs: ["เว็บบอร์ด"],
        },
      },
      {
        path: "/webboard-detail/:id",
        name: "webboard-detail",
        component: () => import("@/views/menu/webboard/WebboardDetail.vue"),
        meta: {
          pageTitle: "เว็บบอร์ด",
          breadcrumbs: ["เว็บบอร์ด"],
        },
      },
      {
        path: "/webboard-create",
        name: "webboard-create",
        component: () => import("@/views/menu/webboard/WebboardManage.vue"),
        meta: {
          pageTitle: "เว็บบอร์ด",
          breadcrumbs: ["เว็บบอร์ด"],
        },
      },
      {
        path: "/webboard-manage/:id",
        name: "webboard-manage",
        component: () => import("@/views/menu/webboard/WebboardManage.vue"),
        meta: {
          pageTitle: "เว็บบอร์ด",
          breadcrumbs: ["เว็บบอร์ด"],
        },
      },
      {
        path: "/riskarea",
        name: "riskarea-list",
        component: () => import("@/views/menu/riskarea/RiskareaList.vue"),
        meta: {
          pageTitle: "จุดเสี่ยง",
          breadcrumbs: ["จุดเสี่ยง"],
        },
      },
      {
        path: "/riskarea-detail/:id",
        name: "riskarea-detail",
        component: () => import("@/views/menu/riskarea/RiskareaDetail.vue"),
        meta: {
          pageTitle: "จุดเสี่ยง",
          breadcrumbs: ["รายละเอียด"],
        },
      },
      {
        path: "/riskarea-create",
        name: "riskarea-create",
        component: () => import("@/views/menu/riskarea/RiskareaManage.vue"),
        meta: {
          pageTitle: "จุดเสี่ยง",
          breadcrumbs: ["รายละเอียด"],
        },
      },
      {
        path: "/riskarea-manage/:id",
        name: "riskarea-manage",
        component: () => import("@/views/menu/riskarea/RiskareaManage.vue"),
        meta: {
          pageTitle: "จุดเสี่ยง",
          breadcrumbs: ["รายละเอียด"],
        },
      },
        {
        path: "/police-location",
        name: "police-location",
        component: () => import("@/views/menu/policelocation/PolicelocationList.vue"),
        meta: {
          pageTitle: "พิกัดเจ้าหน้าที่",
          breadcrumbs: ["จุดเสี่ยง"],
        },
      },
      {
        path: "/police-location-detail/:id",
        name: "police-location-detail",
        component: () => import("@/views/menu/policelocation/PolicelocationDetail.vue"),
        meta: {
          pageTitle: "พิกัดเจ้าหน้าที่",
          breadcrumbs: ["รายละเอียด"],
        },
      },
      {
        path: "/case",
        name: "case-list",
        component: () => import("@/views/menu/case/CaseList.vue"),
        meta: {
          pageTitle: "คดี",
          breadcrumbs: ["คดี"],
        },
      },
      {
        path: "/case-create",
        name: "case-create",
        component: () => import("@/views/menu/case/CaseManage.vue"),
        meta: {
          pageTitle: "คดี",
          breadcrumbs: ["สร้างคดี"],
        },
      },
      {
        path: "/case-manage/:id",
        name: "case-manage",
        component: () => import("@/views/menu/case/CaseManage.vue"),
        meta: {
          pageTitle: "คดี",
          breadcrumbs: ["รายละเอียดคดี"],
        },
      },
      {
        path: "/schedule-calendar",
        name: "schedule-calendar",
        component: () => import("@/views/menu/schedule-tasks/Scheduletasks.vue"),
        meta: {
          pageTitle: "ตารางเวร",
          breadcrumbs: ["ปฏิทินตารางเวร"],
        },
      },
      {
        path: "/schedule-list",
        name: "schedule-list",
        component: () => import("@/views/menu/schedule-tasks/ScheduletasksList.vue"),
        meta: {
          pageTitle: "ตารางเวร",
          breadcrumbs: ["รายการตารางเวร"],
        },
      },
      {
        path: "/notes",
        name: "notes-list",
        component: () => import("@/views/menu/notes/NotesList.vue"),
        meta: {
          pageTitle: "บันทึกของฉัน",
          breadcrumbs: ["รายการบันทึกของฉัน"],
        },
      },
      {
        path: "/notes-create",
        name: "notes-create",
        component: () => import("@/views/menu/notes/NotesManage.vue"),
        meta: {
          pageTitle: "บันทึกของฉัน",
          breadcrumbs: ["รายการบันทึกของฉัน"],
        },
      },
      {
        path: "/notes-detail/:id",
        name: "notes-detail",
        component: () => import("@/views/menu/notes/NotesDetail.vue"),
        meta: {
          pageTitle: "บันทึกของฉัน",
          breadcrumbs: ["รายการบันทึกของฉัน"],
        },
      },
      {
        path: "/notes-manage/:id",
        name: "notes-manage",
        component: () => import("@/views/menu/notes/NotesManage.vue"),
        meta: {
          pageTitle: "บันทึกของฉัน",
          breadcrumbs: ["รายการบันทึกของฉัน"],
        },
      },
      {
        path: "/contact",
        name: "contact-us",
        component: () => import("@/views/menu/contact/Contact.vue"),
        meta: {
          pageTitle: "ติดต่อเรา",
          breadcrumbs: ["ติดต่อเรา"],
        },
      },
      {
        path: "/profile/:id",
        name: "profile-manage",
        component: () => import("@/views/menu/profile/Profile.vue"),
        meta: {
          pageTitle: "ข้อมูลส่วนตัว",
          breadcrumbs: ["ข้อมูลส่วนตัว"],
        },
      },
      {
        path: "/help",
        name: "help",
        component: () => import("@/views/menu/help/Help.vue"),
        meta: {
          pageTitle: "คำถามที่พบบ่อย",
          breadcrumbs: ["คำถามที่พบบ่อย"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "เข้าสู่ระบบ",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "สมัครสมาชิก",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
      {
        path: "/cyber-village-poll/:vId/:pId/:uId/:token",
        name: "cyber-village-poll",
        component: () => import("@/views/CyberVillagePoll.vue"),
        meta: {
          pageTitle: "แบบสอบถาม - KMPPP",
        },
      },
      {
        path: "/cyber-village-webview/:token",
        name: "cyber-village-webview-create",
        component: () => import("@/views/CyberVillageWebView.vue"),
        meta: {
          pageTitle: "cyber-village - KMPPP",
        },
      },
      {
        path: "/cyber-village-webview/:id/:token",
        name: "cyber-village-webview-manage",
        component: () => import("@/views/CyberVillageWebView.vue"),
        meta: {
          pageTitle: "cyber-village - KMPPP",
        },
      },
      {
        path: "/exam-mobile/:cId/:secId/:chId/:exId/:type/:uId/:token",
        name: "exam-mobile",
        component: () => import("@/views/ExamWebView.vue"),
        meta: {
          pageTitle: "คอร์สออนไลน์",
          breadcrumbs: ["ข้อสอบ"],
        },
      },
      {
        path: "/success",
        name: "success-alert",
        component: () => import("@/views/Success.vue"),
        meta: {
          pageTitle: "ทำรายการสำเร็จ",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
// const scrollBehavior = (to, from, savedPosition) => {
//   
//   return savedPosition || { top: 0, left: 0 }
// }
const router = createRouter({
  history: createWebHistory(),
  routes,
  // scrollBehavior
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    // console.log(store.getters.isUserAuthenticated);
    if (store.getters.isUserAuthenticated) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
